import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from "./Home";
import DeleteAccount from "./DeleteAccount";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/delete-account" element={<DeleteAccount />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/" element={<Home />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;