import './App.css';
import video from './media/glasses.mp4';
import Clubpass from './media/clubpass.png';
import DarkClubpass from './media/clubpass-dark.png';
import QRScan from './media/qrscan.jpg';
import TrueChart from './media/trueChart.png';
import GetTogether from './media/get-together.jpg';
import Screens from './media/screens.png';
import { useEffect, useRef, useState } from 'react';

function App() {

    const videoRef = useRef(null);

    const buttonRef1 = useRef(null);

    const [count, setCount] = useState(0);
    const [boxTranslate, setBoxTranslate] = useState(0);
    const [boxRotate, setBoxRotate] = useState(0);


    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = 0.6;

            //Required for safari mobile 🪄
            videoRef.current.playsInline = true
            videoRef.current.play();

        }
    }, []);



    // Ogni 10 secondi voglio che sectionBox1 e sectionBox2 vadano su, e poi dopo altyri 10 secondi voglio che tornino giù
    useEffect(() => {
        const interval = setInterval(() => {
            if (count % 2 === 0) {
                if (window.innerWidth > 600) {
                    setBoxTranslate(-206);
                }
                else {
                    setBoxTranslate(-340);
                }
                setBoxRotate(90);
            } else {
                setBoxTranslate(0);
                setBoxRotate(0);
            }
            setCount(count + 1);
        }, 5000);

        return () => clearInterval(interval);
    }, [count]);


    const onClick01 = () => {
        window.location.href = "#section01";
    }
    const onClick02 = () => {
        window.location.href = "#section02";
    }
    const onClick03 = () => {
        window.location.href = "#section03";
    }
    const onClickDemo = () => {
        window.location.href = "#demo";
    }
    const onClickContact = () => {
        window.location.href = "#contact";
    }



    return (
        <div>
            {window.innerWidth > 600 ?
                <div className="App">
                    <img src={Clubpass} alt="Clubpass" style={{ position: "absolute", top: 0, left: 40, width: "20%", minWidth: "300px", maxWidth: "400px", borderRadius: "10px", margin: "auto", marginBottom: 0 }} />

                    <video ref={videoRef} loop={true} autoPlay={true} muted={true} preload="metadata" style={{
                        position: "absolute",
                        zIndex: -1,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <hr style={{ position: "absolute", top: "50%", left: 0, width: 600, margin: 0, border: 0, borderTop: "1px solid white" }} />
                    <p style={{ color: "white", position: "absolute", left: 20, top: "50%" }}>Forniamo un modo nuovo di vivere gli eventi,<br /> una nuova esperienza per organizzatori e clienti</p>
                    <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end" }}>
                        <div className='section-box' style={{ margin: "40px", overflow: 'hidden', height: '210px' }}>
                            <div className='section' style={{ transform: `translateY(${boxTranslate}px) rotateX(${boxRotate}deg)` }} onClick={onClick01}>
                                <img src={QRScan} alt="QRScan" style={{ width: 250, borderRadius: "10px" }} />
                                <div style={{ marginLeft: 20, width: 250, height: 150 }}>
                                    <h3>Codice QR</h3>
                                    <hr style={{ width: "100%", margin: 0, border: 0, borderTop: "1px solid white" }} />
                                    <p>Scansione facile e veloce</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill='white'><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" /></svg>
                            </div>
                            <div className='section' style={{ transform: `translateY(${boxTranslate}px) rotateX(${boxRotate - 90}deg)` }} onClick={onClick02}>
                                <img src={TrueChart} alt="Chart" style={{ width: 250, borderRadius: "10px" }} />
                                <div style={{ marginLeft: 20, width: 250, height: 150 }}>
                                    <h3>Dati in tempo reale</h3>
                                    <hr style={{ width: "100%", margin: 0, border: 0, borderTop: "1px solid white" }} />
                                    <p>Forniamo statistiche in tempo reale sull'andamento dei tuoi eventi</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill='white'><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" /></svg>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-evenly", margin: "40px", marginTop: 0, width: 590 }}>
                            <button className='feed-button' onClick={onClickDemo}>Richiedi una demo</button>
                            <button className='feed-button' onClick={onClickContact}>Contattaci</button>
                        </div>
                    </div>
                    <h1 style={{ fontWeight: "normal" }}>Cosa offriamo</h1>
                    <hr style={{ marginInline: "40px", border: 0, borderTop: "1px solid grey" }} />
                    <div id="section01" style={{ display: "flex", justifyContent: "space-evenly", margin: "40px" }}>
                        <h2 style={{ fontWeight: "normal", width: "5%", color: "grey" }}>01</h2>
                        <h2 style={{ fontWeight: "normal", width: "20%" }}>Gestione degli Ordini</h2>
                        <p style={{ width: "30%" }}>Forniamo QR code scansionabili dal personale per tenere traccia degli ordini dei clienti in tempo reale. Ogni transazione viene registrata per garantire un servizio rapido e senza errori, migliorando l’esperienza nei locali</p>
                        <img src={QRScan} alt="QRScan" style={{ width: "30%", borderRadius: "10px" }} />
                    </div>
                    <hr style={{ marginInline: "40px", border: 0, borderTop: "1px solid grey" }} />
                    <div id="section02" style={{ display: "flex", justifyContent: "space-evenly", margin: "40px" }}>
                        <h2 style={{ fontWeight: "normal", width: "5%", color: "grey" }}>02</h2>
                        <h2 style={{ fontWeight: "normal", width: "20%" }}>Statistiche in tempo reale</h2>
                        <p style={{ width: "30%" }}>Forniamo statistiche in tempo reale sull’andamento degli eventi, per permettere agli organizzatori di avere un controllo totale e prendere decisioni informate</p>
                        <img src={TrueChart} alt="Chart" style={{ width: "30%", borderRadius: "10px" }} />
                    </div>
                    <hr style={{ marginInline: "40px", border: 0, borderTop: "1px solid grey" }} />
                    <div id="section03" style={{ display: "flex", justifyContent: "space-evenly", margin: "40px" }}>
                        <h2 style={{ fontWeight: "normal", width: "5%", color: "grey" }}>03</h2>
                        <h2 style={{ fontWeight: "normal", width: "20%" }}>Scansione facile e veloce</h2>
                        <p style={{ width: "30%" }}>Forniamo un sistema di scansione QR code facile e veloce, per permettere ai clienti di accedere agli eventi in modo rapido e sicuro</p>
                        <img src={Screens} alt="QRScan" style={{ width: "30%", borderRadius: "10px" }} />
                    </div>
                    <hr style={{ marginInline: "40px", border: 0, borderTop: "1px solid grey" }} />
                    <div style={{ display: "flex", justifyContent: "space-evenly", margin: "40px" }}>
                        <div id='demo' style={{ width: "45%", aspectRatio: 1, backgroundColor: "#e5e4e4", borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div>
                                <h3 style={{ fontWeight: "normal", marginLeft: 40, marginTop: 40, marginBottom: -20, textAlign: "left" }}>Prova una Demo Gratuita</h3>
                                <h1 style={{ fontSize: 50, fontWeight: "normal" }}>Scopri il potenziale del nostro servizio</h1>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                                <a href="mailto:info@clubpass.it" className='contact-button'>Richiedi una demo</a>
                                <img src={DarkClubpass} alt="Clubpass" style={{ width: "50%", borderRadius: "10px" }} />
                            </div>
                        </div>
                        <div id='contact' style={{
                            width: "45%", aspectRatio: 1, backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ),url(${GetTogether})`, backgroundSize: "cover", color: "white",
                            borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between"
                        }}>
                            <div>
                                <h3 style={{ fontWeight: "normal", marginLeft: 40, marginTop: 40, marginBottom: -20, textAlign: "left" }}>Collaborazioni</h3>
                                <h1 style={{ fontSize: 50, fontWeight: "normal" }}>Costruiamo esperienze insieme</h1>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", padding: 30 }}>
                                <a href="mailto:info@clubpass.it" ref={buttonRef1} className='contact-button'>Contattaci</a>
                            </div>
                        </div>
                    </div>
                </div>

                :

                <div className="App">
                    <video ref={videoRef} loop autoPlay muted style={{
                        position: "absolute",
                        zIndex: -1,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
                        <img src={Clubpass} alt="Clubpass" style={{ position: "relative", width: "80%" }} />
                        <div className='section-box' style={{ margin: "20px", overflow: 'hidden', width: "80%", height: 400 }}>
                            <div className='section' style={{ height: 300, transform: `translateY(${boxTranslate}px) rotateX(${boxRotate}deg)`, flexDirection: "column" }} onClick={onClick01}>
                                <img src={QRScan} alt="QRScan" style={{ width: "100%", borderRadius: "10px" }} />
                                <div style={{ marginLeft: 20, height: 150 }}>
                                    <h3>Codice QR</h3>
                                    <hr style={{ width: "80%", margin: 0, border: 0, borderTop: "1px solid white" }} />
                                    <p>Scansione facile e veloce</p>
                                </div>
                            </div>
                            <div className='section' style={{ height: 300, transform: `translateY(${boxTranslate}px) rotateX(${boxRotate - 90}deg)`, flexDirection: "column" }} onClick={onClick02}>
                                <img src={TrueChart} alt="Chart" style={{ width: "100%", borderRadius: "10px" }} />
                                <div style={{ marginLeft: 20, height: 150 }}>
                                    <h3>Dati in tempo reale</h3>
                                    <hr style={{ width: "80%", margin: 0, border: 0, borderTop: "1px solid white" }} />
                                    <p>Forniamo statistiche in tempo reale sull'andamento dei tuoi eventi</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: 20, width: "100%" }}>
                            <button className='feed-button' style={{ padding: "16px", fontSize: "16px" }} onClick={onClickDemo}>Richiedi una demo</button>
                            <button className='feed-button' style={{ padding: "16px", fontSize: "16px" }} onClick={onClickContact}>Contattaci</button>
                        </div>
                    </div>
                    <h1 style={{ fontWeight: "normal" }}>Cosa offriamo</h1>
                    <hr style={{ marginInline: "20px", border: 0, borderTop: "1px solid grey" }} />
                    <div id="section01" style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", margin: "20px" }}>
                        <h2 style={{ fontWeight: "normal", color: "grey" }}>01</h2>
                        <h2 style={{ fontWeight: "normal" }}>Gestione degli Ordini</h2>
                        <p>Forniamo QR code scansionabili dal personale per tenere traccia degli ordini dei clienti in tempo reale. Ogni transazione viene registrata per garantire un servizio rapido e senza errori, migliorando l’esperienza nei locali</p>
                        <img src={QRScan} alt="QRScan" style={{ borderRadius: "10px" }} />
                    </div>
                    <hr style={{ marginInline: "20px", border: 0, borderTop: "1px solid grey" }} />
                    <div id="section02" style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", margin: "20px" }}>
                        <h2 style={{ fontWeight: "normal", color: "grey" }}>02</h2>
                        <h2 style={{ fontWeight: "normal" }}>Statistiche in tempo reale</h2>
                        <p>Forniamo statistiche in tempo reale sull’andamento degli eventi, per permettere agli organizzatori di avere un controllo totale e prendere decisioni informate</p>
                        <img src={TrueChart} alt="Chart" style={{ borderRadius: "10px" }} />
                    </div>
                    <hr style={{ marginInline: "20px", border: 0, borderTop: "1px solid grey" }} />
                    <div id="section03" style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", margin: "20px" }}>
                        <h2 style={{ fontWeight: "normal", color: "grey" }}>03</h2>
                        <h2 style={{ fontWeight: "normal"}}>Scansione facile e veloce</h2>
                        <p>Forniamo un sistema di scansione QR code facile e veloce, per permettere ai clienti di accedere agli eventi in modo rapido e sicuro</p>
                        <img src={Screens} alt="QRScan" style={{ borderRadius: "10px" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", margin: "20px" }}>
                        <div id='demo' style={{ aspectRatio: 1, backgroundColor: "#e5e4e4", borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between", marginBottom: 20 }}>
                            <div>
                                <h3 style={{ fontWeight: "normal", marginLeft: 40, marginTop: 40, marginBottom: -20, textAlign: "left" }}>Prova una Demo Gratuita</h3>
                                <h1 style={{ fontSize: 30, fontWeight: "normal" }}>Scopri il potenziale del nostro servizio</h1>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", padding: 30 }}>
                                <a href="mailto:info@clubpass.it" className='contact-button'>Richiedi una demo</a>
                            </div>
                        </div>
                        <div id='contact' style={{
                            aspectRatio: 1, backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ),url(${GetTogether})`, backgroundSize: "cover", color: "white",
                            borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between"
                        }}>
                            <div>
                                <h3 style={{ fontWeight: "normal", marginLeft: 40, marginTop: 40, marginBottom: -20, textAlign: "left" }}>Collaborazioni</h3>
                                <h1 style={{ fontSize: 30, fontWeight: "normal" }}>Costruiamo esperienze insieme</h1>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", padding: 30 }}>
                                <a href="mailto:info@clubpass.it" ref={buttonRef1} className='contact-button'>Contattaci</a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default App;
