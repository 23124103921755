import "./DeleteAccount.css"
import Settings from "./media/settings.PNG"
import Confirm from "./media/confirm.PNG"

function NewPage() {
    return (
        <div className="deleteAccount container">
            <h1>This page will show how to delete ClubPass Account</h1>
            <p>Here is a list of steps to delete your account:</p>
            <ol>
                <li><b>Step 1:</b> Open to the ClubPass APP</li>
                <li><b>Step 2:</b> Log in to your account</li>
                <li><b>Step 3:</b> Open the "Settings" Page </li>
                <li><b>Step 4:</b> Press on "Delete Account" option</li>
                <li><b>Step 5:</b> Confirm the operation</li>
            </ol>

            <p>Below some useful screenshot</p>
            <img src={Settings} alt="settings page"/>
            <img src={Confirm} alt="Confmeration message"/>

        </div>
    );
}

export default NewPage;